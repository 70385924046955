body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

canvas {
  max-width: 100% !important;
  height: auto !important;
}

input[type="file"] {
  color: transparent;
}

@font-face {
  font-family: "Menlo";
  src: local("Menlo"), url(./fonts/Menlo-Regular.ttf) format("truetype");
}

/* h1,
h2,
h3,
h4,
a,
p {
  font-family: "Arial";
} */

.OGtext {
  font-family: "Arial" !important;
  font-style: "normal" !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 32px !important;
}

.CRYPTO-OGtext {
  font-family: "Courier New" !important;
  font-style: "normal" !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 32px !important;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: black !important;
}
